// ==========================================================================
// Plyr states
// ==========================================================================

export const pip = {
    active: 'picture-in-picture',
    inactive: 'inline',
};

export default { pip };
